import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router';
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FullPageWidthWrapper from '../../patterns/molecules/Layout/FullPageWidthWrapper'
import { setBreadCrumb } from '../../store/actions/ProductListActions'

const BreadcrumbTastic = ({ data, node }) => {


  const router = useRouter()
  const [hasHistory, setHasHistory] = useState(false)
  const { breadcrumb, isDarkMode } = data
  const history = useHistory()
  const isMobile = useDeviceType() === 'mobile'
  const sizeArrow = isMobile ? 15 : 20
  const isPDP = node.nodeType === 'product'
  const isCategoryPage = node.nodeType === 'category'
  const breadcrumbByCategory = useSelector((state) => state['product-list-algolia']).breadcrumb

  const urlBrandDetail = router.asPath.match(/(\/brands)/)
  const indexLastBreadcrumb = breadcrumb?.length
  const dispatcher = useDispatch()

  useEffect(() => {
    if (window.history.state) {
      setHasHistory(true)
    }
    dispatcher(setBreadCrumb({
      breadcrumb: [],
    }))
  }, [])

  if (!data || !data.breadcrumb) {
    return null
  }

  const renderNode = () => {
    if (!breadcrumb) {
      return null
    }
    const newBreadCrumb = isCategoryPage && breadcrumbByCategory.length ? breadcrumbByCategory : breadcrumb
    const lastIndexItem = newBreadCrumb.length - 1

    return (
      newBreadCrumb.map((item, index) => {
        if (index === lastIndexItem && isCategoryPage && breadcrumbByCategory.length) {
          return (
            <li
              itemProp={'itemListElement'}
              itemScope
              itemType={'https://schema.org/ListItem'}
              className={'breadcrumb-item hidden md:inline-block active'}
              key={item.label}
            >
              <span itemProp={'name'}>{item.label}</span>
              <meta itemProp={'position'} content={indexLastBreadcrumb} />
            </li>
          )
        }
        return (
          <li
            itemProp={'itemListElement'}
            itemScope
            itemType={'https://schema.org/ListItem'}
            className={classnames('breadcrumb-item', {
              'hidden md:inline-block': !isPDP,
              active: isPDP && index === lastIndexItem,
            })}
            key={item.label}
          >
            <Link
              itemProp={'item'}
              href={item.url}
              className={'o-link-hover--underline'}
            >
              <span itemProp={'name'}>{item.label}</span>
            </Link>
            <meta itemProp={'position'} content={index} />
          </li>
        )
      })
    )
  }

  const renderActiveNode = () => {
    if (node.nodeType === 'product' || (isCategoryPage && breadcrumbByCategory.length)) {
      return null
    }

    if (node.nodeType === 'content') {
      const blogArticle = data?.stream?.attributes[0]?.content?.blogArticle
      if (blogArticle) {
        return (
          <li
            itemProp={'itemListElement'}
            itemScope
            itemType={'https://schema.org/ListItem'}
            className={'breadcrumb-item hidden md:inline-block active'}
          >
            <span itemProp={'name'}>{blogArticle.headline}</span>
            <meta itemProp={'position'} content={breadcrumb.length} />
          </li>
        )
      }
    }

    return (
      <li
        itemProp={'itemListElement'}
        itemScope
        itemType={'https://schema.org/ListItem'}
        className={'breadcrumb-item hidden md:inline-block active'}
      >
        <span itemProp={'name'}>{node.name}</span>
        <meta itemProp={'position'} content={breadcrumb.length} />
      </li>
    )
  }

  return (
    <FullPageWidthWrapper
      withWrapper
      customClasses={classnames({
        'bg-blacksport-100': isDarkMode,
        'brand-detail--breadcrumb': urlBrandDetail,
      })}
    >
      <div className={'breadcrumb-wrapper'}>
        <ul
          itemScope
          itemType={'https://schema.org/BreadcrumbList'}
          className={classnames('breadcrumb', { 'is--darkmode': isDarkMode })}
        >
          {/*<li*/}
          {/*    className={classnames('btn-back', {*/}
          {/*        hidden: !hasHistory,*/}
          {/*    })}*/}
          {/*>*/}
          {/*    <a*/}
          {/*        onClick={() => {*/}
          {/*            router.back()*/}
          {/*        }}*/}
          {/*    >*/}
          {/*        <ArrowLeft*/}
          {/*            className={'icon-arrow-l'}*/}
          {/*            width={sizeArrow}*/}
          {/*            height={sizeArrow}*/}
          {/*        />*/}
          {/*        <span className={'text'}><FormattedMessage id={'breadCrumb.back'} /></span>*/}
          {/*    </a>*/}
          {/*</li>*/}
          <li
            className={classnames('breadcrumb-item', {
              'hidden md:inline-block': !isPDP,
            })}
          >
            <Link
              href={'/'}
              className={'o-link-hover--underline'}
            >
              <FormattedMessage id={'breadCrumb.home'} />
            </Link>
          </li>
          {renderNode()}
          {renderActiveNode()}
        </ul>
      </div>
    </FullPageWidthWrapper>
  )
}
BreadcrumbTastic.propTypes = {
  node: PropTypes.object.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
}
export default tastify({ translate: true, connect: { cart: true, node: true } })(injectIntl(BreadcrumbTastic))
